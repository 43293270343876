import React from "react";
import { Element } from "react-scroll";

import PageWrapper from "../components/PageWrapper";
import HeroStatic from "../sections/peel-creative/HeroStatic";
import TextBlock from "../sections/peel-creative/TextBlock";

import HeroImage from "../assets/peel-creative/about_hero.jpg"

const IndexPage = () => {
  return (
    <>
      <PageWrapper>
        <HeroStatic image={HeroImage} />
        <div>
        <TextBlock pb={5} title="About" copy="<strong>Great design is about choice.</strong> It helps customers choose one product or business over the next. Great design connects people with what your business offers. It helps them have confidence in your product or service, so that they can make their choice. We love design. We understand the value great design adds to a business. We believe, because of the way we work, we can develop a design that will work for you. To get you noticed for the right reasons. To help people understand why they should choose you. Design is the visual expression of your promise to the market. Our design helps build your reputation and adds genuine value to your business.
<br/><br/>
In order to see the solution, we must first see the problem. It’s about understanding the business, the issues facing the business, the barriers, the objectives, the message. We do this with you. Peeling away the things that get in the way - getting to the core of the issue. It’s not always easy but it’s all about caring enough to get through the barriers. We look for the emotional truth of the product or service because the truth is the thing which resonates with your customers. Once we understand the emotional triggers that drive choice, we build the design around that truth. We find a way to celebrate the promise of the product."/>

        </div>
      </PageWrapper>
    </>
  );
};
export default IndexPage;
